exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cgvu-js": () => import("./../../../src/pages/cgvu.js" /* webpackChunkName: "component---src-pages-cgvu-js" */),
  "component---src-pages-checkout-success-js": () => import("./../../../src/pages/checkout-success.js" /* webpackChunkName: "component---src-pages-checkout-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-sections-about-js": () => import("./../../../src/pages/index-sections/about.js" /* webpackChunkName: "component---src-pages-index-sections-about-js" */),
  "component---src-pages-index-sections-contact-js": () => import("./../../../src/pages/index-sections/contact.js" /* webpackChunkName: "component---src-pages-index-sections-contact-js" */),
  "component---src-pages-index-sections-history-js": () => import("./../../../src/pages/index-sections/history.js" /* webpackChunkName: "component---src-pages-index-sections-history-js" */),
  "component---src-pages-index-sections-home-js": () => import("./../../../src/pages/index-sections/home.js" /* webpackChunkName: "component---src-pages-index-sections-home-js" */),
  "component---src-pages-index-sections-logos-js": () => import("./../../../src/pages/index-sections/logos.js" /* webpackChunkName: "component---src-pages-index-sections-logos-js" */),
  "component---src-pages-index-sections-news-js": () => import("./../../../src/pages/index-sections/news.js" /* webpackChunkName: "component---src-pages-index-sections-news-js" */),
  "component---src-pages-index-sections-products-js": () => import("./../../../src/pages/index-sections/products.js" /* webpackChunkName: "component---src-pages-index-sections-products-js" */),
  "component---src-pages-pdc-js": () => import("./../../../src/pages/pdc.js" /* webpackChunkName: "component---src-pages-pdc-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */)
}

